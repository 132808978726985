import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Loadable } from '@rflex-spa/react-core/dist/routes';
// layouts
import DashboardLayout from '@rflex-spa/react-core/dist/base/layouts/dashboard';

// guards
import DTAuthGuard from '../guards/DTAuthGuard';
import AuthGuard from '../guards/AuthGuard';
// navigation
import IndexRedirect from '../navigation/IndexRedirect';
// configs
import navConfig from './navConfig';
import workerNavConfig from './workerNavConfig';
import dtNavConfig from './dtNavConfig';
import {
  ROUTES,
  APP_REPORT_ROUTES,
  APP_DT_REPORTS_ROUTES,
  APP_DT_ROUTES,
  APP_WORKERS_ROUTES,
  APP_PROTOCOL_IMPROVEMENT_ROUTES,
  APP_MAINTAINERS_ROUTES,
  APP_LEAVE_ROUTES,
  APP_IMPORTERS_ROUTES,
  APP_DOCUMENT_MANAGEMENT_ROUTES,
  PUBLIC_ROUTES,
} from './paths';

// wrappers
import PageWrapper from '../components/wrappers/PageWrapper';
import InitialConfigsWrapper from '../components/wrappers/InitialConfigsWrapper';

// ----------------------------------------------------------------------
export const getRoutes = () => [
  {
    code: 'index',
    path: ROUTES.root,
    element: <IndexRedirect />,
    index: true,
  },
  {
    path: ROUTES.root,
    children: [
      { path: PUBLIC_ROUTES.enterValidationCode, element: <ValidationCodeForm /> },
      { path: PUBLIC_ROUTES.rejectMarkCorrection, element: <RejectMarkCorrectionView /> },
    ]
  },
  {
    path: ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'home', path: ROUTES.home, element: <HomeView /> },
      { code: 'payment-periods', path: ROUTES.closingPeriods, element: <ClosingPeriodsView /> },
      { code: 'units', path: ROUTES.unitsMaintainer, element: <UnitsMaintainerView /> },
      { code: 'reports', path: APP_REPORT_ROUTES.root, element: <ReportView /> },
      { code: 'hash-validation', path: ROUTES.hashValidation, element: <HashValidationView /> },
      { code: 'user-profile', path: ROUTES.user.profile, element: <Profile /> },
      { code: 'mantenedor-trabajadores', path: APP_WORKERS_ROUTES.workers, element: <Workers /> },
      { code: 'ficha-unica', path: APP_WORKERS_ROUTES.singleSheet, element: <SingleSheetView /> },
      { code: 'clock-devices', path: ROUTES.clockDevices, element: <ClockDevices /> },
      { code: 'monitor-relojes', path: ROUTES.clockMonitor, element: <ClockMonitorView /> },
      { code: 'planillas', path: ROUTES.spreadsheets, element: <SpreadSheetsView /> },
      { code: 'planillas', path: ROUTES.spreadsheetDetail, element: <SpreadsheetDetailView /> },
      { code: 'maintainers', path: APP_MAINTAINERS_ROUTES.root, element: <MaintainerView /> },
      { code: 'protocol-improvement', path: APP_PROTOCOL_IMPROVEMENT_ROUTES.root, element: <ProtocolImprovementView /> },
      { code: 'permisos', path: APP_LEAVE_ROUTES.root, element: <LeavesView /> },
      { code: 'importers', path: APP_IMPORTERS_ROUTES.root, element: <ImportersView /> },
      { code: 'extra-hours-administrator', path: ROUTES.extraHours, element: <ExtraHoursView /> },
      { code: 'document-management', path: APP_DOCUMENT_MANAGEMENT_ROUTES.root, element: <DocumentManagementView /> },
    ],
  },
  {
    path: ROUTES.root,
    element: (
      <AuthGuard>
        <InitialConfigsWrapper>
          <DashboardLayout navConfig={workerNavConfig} />
        </InitialConfigsWrapper>
      </AuthGuard>
    ),
    children: [{ code: 'teletrabajo', path: ROUTES.teletrabajo, element: <RemoteWorkView /> }],
  },
  {
    path: APP_REPORT_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'report-called', path: 'called', element: <CalledView /> },
      { code: 'report-mark-correction', path: 'mark-correction', element: <MarkCorrection /> },
      { code: 'report-payroll', path: 'payroll', element: <PayrollMaintainerView /> },
      { code: 'report-balance-hours', path: 'balance-hours', element: <BalanceHoursView /> },
      { code: 'report-raw-shift', path: 'raw-shift', element: <RawShiftView /> },
      { code: 'report-responsability-bonus', path: 'responsability-bonus', element: <ResponsabilityBonusView /> },
      { code: 'report-bonifications', path: 'improvements', element: <ImprovementView /> },
      { code: 'report-honorarium', path: 'honorarium', element: <HonorariumView /> },
      { code: 'report-hours-not-worked', path: 'hours-not-worked', element: <HoursNotWorkedView /> },
      { code: 'report-holidays-time', path: 'holidays-time', element: <HolidaysTimeView /> },
      { code: 'report-puntuality-bonus', path: 'puntuality-bonus', element: <PuntualityBonusView /> },
      { code: 'report-time-clock', path: 'time-clock', element: <TimeClockView /> },
      { code: 'report-shift-changes', path: 'shift-changes', element: <ShiftChanges /> },
      { code: 'report-extra-shifts', path: 'extra-shifts', element: <ExtraShiftsView /> },
      { code: 'report-attendance-sheets', path: 'attendance-sheets', element: <AttendanceSheets /> },
      { code: 'report-personnel-by-sector', path: 'personnel-by-sector', element: <PersonnelBySectorView /> },
      { code: 'report-payment-interface', path: 'payment-interface', element: <PaymentInterfaceView /> },
      { code: 'report-buk-payment-interface', path: 'buk-payment-interface', element: <BukPaymentInterfaceView /> },
      { code: 'report-leave', path: 'leave', element: <LeaveView /> },
      { code: 'report-causes-of-dismissals', path: 'causes-of-dismissals', element: <CausesOfDismissalsView /> },
      { code: 'report-punches', path: 'punches', element: <PunchesView /> },
      { code: 'report-punches-without-shift', path: 'punches-without-shift', element: <PunchesWithoutShift /> },
      { code: 'report-meals-punches', path: 'punches-meals', element: <PunchesMealsView /> },
      // TODO: Refactorizar y utilizar una ruta y no un parametro
      { code: 'report-called', path: 'called-legacy', element: <GenericReportGDPView report='called-legacy' /> },
      { code: 'report-improvement-hours', path: 'improvement-hours', element: <GenericReportGDPView report='improvement-hours' /> },
      { code: 'report-shift-delivery', path: 'shift-delivery', element: <GenericReportGDPView report='shift-delivery' /> },
      { code: 'report-clothing-improvement', path: 'clothing-improvement', element: <GenericReportGDPView report='clothing-improvement' /> },
      { code: 'report-assistance', path: 'assistance', element: <GenericReportGDPView report='assistance' /> },
      { code: 'report-extra-shifts', path: 'extra-shifts-legacy', element: <GenericReportGDPView report='extra-shifts-legacy' /> },
      { code: 'report-responsability-bonus', path: 'responsability-bonus-legacy', element: <GenericReportGDPView report='responsability-bonus-legacy' /> },
    ],
  },
  {
    path: APP_DT_REPORTS_ROUTES.root,
    element: (
      <DTAuthGuard>
        <InitialConfigsWrapper>
          <DashboardLayout navConfig={dtNavConfig} />
        </InitialConfigsWrapper>
      </DTAuthGuard>
    ),
    children: [
      { path: APP_DT_REPORTS_ROUTES.root, element: <CertificationReports /> },
      { path: APP_DT_REPORTS_ROUTES.assistance, element: <AssistanceReport /> },
      { path: APP_DT_REPORTS_ROUTES.dailyWork, element: <DailyWork /> },
      { path: APP_DT_REPORTS_ROUTES.sundayHolidays, element: <SundayAndHolidays /> },
      { path: APP_DT_REPORTS_ROUTES.modificationsAlterationsShift, element: <ModificationsAndAlterationsShift /> },
    ],
  },
  {
    path: APP_DT_ROUTES.hashValidation,
    element: (
      <DTAuthGuard>
        <InitialConfigsWrapper>
          <DashboardLayout navConfig={dtNavConfig} />
        </InitialConfigsWrapper>
      </DTAuthGuard>
    ),
    children: [{ path: APP_DT_ROUTES.hashValidation, element: <HashValidationView /> }],
  },
  {
    path: 'dt',
    element: <Navigate to={APP_DT_REPORTS_ROUTES.root} replace />,
  },
  {
    path: 'dt',
    element: (
      <DTAuthGuard>
        <InitialConfigsWrapper>
          <DashboardLayout navConfig={dtNavConfig} />
        </InitialConfigsWrapper>
      </DTAuthGuard>
    ),
    children: [
      { path: 'home', element: <HomeDtView /> },
      { path: 'company-selection', element: <InstanceOfView /> },
    ],
  },
  {
    path: APP_PROTOCOL_IMPROVEMENT_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'protocol-improvement-contract', path: APP_PROTOCOL_IMPROVEMENT_ROUTES.contract, element: <ProtocolImprovementByContract /> },
      { code: 'protocol-improvement-sector', path: APP_PROTOCOL_IMPROVEMENT_ROUTES.sector, element: <ProtocolImprovementBySector /> },
      { code: 'protocol-improvement-shift-type-rotations', path: APP_PROTOCOL_IMPROVEMENT_ROUTES.shiftTypeAndRotations, element: <ProtocolImprovementByShiftTypeAndRotation /> },
      { code: 'protocol-improvement-absence-shift-detail', path: APP_PROTOCOL_IMPROVEMENT_ROUTES.absenceShiftDetail, element: <AbsenceShiftDetailView /> },
    ],
  },
  {
    path: APP_MAINTAINERS_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'maintainers-config-shift', path: APP_MAINTAINERS_ROUTES.configShift, element: <ConfigShiftView /> },
      { code: 'maintainers-audit', path: APP_MAINTAINERS_ROUTES.audit, element: <AuditMaintainerView /> },
      { code: 'maintainer-administrator', path: APP_MAINTAINERS_ROUTES.administrators, element: <AdministratorMaintainerView /> },
      { code: 'maintainer-roles', path: APP_MAINTAINERS_ROUTES.roles, element: <RolesMaintainerView />},
      { code: 'maintainer-roles',path: APP_MAINTAINERS_ROUTES.rolesHistory, element: <RolesHistoryView />}
    ],
  },
  {
    path: APP_LEAVE_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'leave-foodlaw', path: APP_LEAVE_ROUTES.foodlaw, element: <FoodLawView /> },
      { code: 'leave-medical-rest', path: APP_LEAVE_ROUTES.medicalRestLeave, element: <MedicalRestLeave /> },
      { code: 'leave-bulk-leaves', path: APP_LEAVE_ROUTES.bulkLeaves, element: <BulkLeaves /> }
    ],
  },
  {
    path: APP_IMPORTERS_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'shift-importer', path: APP_IMPORTERS_ROUTES.shiftImporter, element: <ShiftImporterView /> },
    ]
  },
  {
    path: APP_DOCUMENT_MANAGEMENT_ROUTES.root,
    element: (
      <PageWrapper>
        <AuthGuard>
          <InitialConfigsWrapper>
            <DashboardLayout navConfig={navConfig} />
          </InitialConfigsWrapper>
        </AuthGuard>
      </PageWrapper>
    ),
    children: [
      { code: 'document-validation', path: APP_DOCUMENT_MANAGEMENT_ROUTES.documentValidation, element: <DocumentValidator /> },
    ]
  }
];

// Dashboard views
const HomeView = Loadable(lazy(() => import('../views/home/HomeView')));
// Worker views
const RemoteWorkView = Loadable(lazy(() => import('../views/remote-work/RemoteWorkView')));
// Reports
const ReportView = Loadable(lazy(() => import('../views/reports/ReportView')));
const CalledView = Loadable(lazy(() => import('../views/reports/called/CalledView')));
const RawShiftView = Loadable(lazy(() => import('../views/reports/raw-shift/RawShiftView')));
const ResponsabilityBonusView = Loadable(lazy(() => import('../views/reports/responsability/ResponsabilityBonusView')));
const ImprovementView = Loadable(lazy(() => import('../views/reports/improvement/ImprovementView')));
const HonorariumView = Loadable(lazy(() => import('../views/reports/honorarium/HonorariumView')));
const HoursNotWorkedView = Loadable(lazy(() => import('../views/reports/hours-not-worked')));
const HolidaysTimeView = Loadable(lazy(() => import('../views/reports/holidays-time/HolidaysTimeView')));
const PuntualityBonusView = Loadable(lazy(() => import('../views/reports/puntuality-bonus/PuntualityBonusView')));
const TimeClockView = Loadable(lazy(() => import('../views/reports/time-clock/TimeClockView')));
const ShiftChanges = Loadable(lazy(() => import('../views/reports/shift-changes/ShiftChangesView')));
const ExtraShiftsView = Loadable(lazy(() => import('../views/reports/extra-shifts/ExtraShiftsView')));
const PersonnelBySectorView = Loadable(
  lazy(() => import('../views/reports/personnel-by-sector/PersonnelBySectorView'))
);
const PaymentInterfaceView = Loadable(lazy(() => import('../views/reports/payment-interface/PaymentInterfaceView')));
const BukPaymentInterfaceView = Loadable(
  lazy(() => import('../views/reports/payment-interface/BukPaymentInterfaceView'))
);
const LeaveView = Loadable(lazy(() => import('../views/reports/leave')));
const CausesOfDismissalsView = Loadable(
  lazy(() => import('../views/reports/causes-of-dismissals/CausesOfDismissalsView'))
);
const PunchesView = Loadable(lazy(() => import('../views/reports/punches')));
const PunchesMealsView = Loadable(lazy(() => import('../views/reports/punches-meals')));
const PunchesWithoutShift = Loadable(lazy(() => import('../views/reports/punch-without-shift')));
// Closing periods
const ClosingPeriodsView = Loadable(lazy(() => import('../views/closing-periods/ClosingPeriodsView')));
// Single Sheet
const SingleSheetView = Loadable(lazy(() => import('../views/single-sheet/SingleSheetView')));
// Units maintainer
const UnitsMaintainerView = Loadable(lazy(() => import('../views/units-maintainer/UnitsMaintainerView')));
// Certification reports
const CertificationReports = Loadable(lazy(() => import('../views/work-address/certification-reports')));
const AssistanceReport = Loadable(lazy(() => import('../views/work-address/certification-reports/Assistance')));
const DailyWork = Loadable(lazy(() => import('../views/work-address/certification-reports/DailyWork')));
const SundayAndHolidays = Loadable(lazy(() => import('../views/work-address/certification-reports/SundayAndHolidays')));
const ModificationsAndAlterationsShift = Loadable(
  lazy(() => import('../views/work-address/certification-reports/ModificationsAndAlterationsShift'))
);
// Hash validation
const HashValidationView = Loadable(lazy(() => import('../views/work-address/hash-validation')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
// Payroll maintainer
const PayrollMaintainerView = Loadable(lazy(() => import('../views/reports/payroll-maintainer/PayrollMaintainerView')));
// Mark correction
const MarkCorrection = Loadable(lazy(() => import('../views/mark-correction')));
const RejectMarkCorrectionView = Loadable(lazy(() => import('../views/mark-correction/reject-mark-correction')));
// Balance hours
const BalanceHoursView = Loadable(lazy(() => import('../views/reports/balance-hours/BalanceHoursView')));
// Workers
const Workers = Loadable(lazy(() => import('../views/workers')));
// Clock devices
const ClockDevices = Loadable(lazy(() => import('../views/clock-devices/ClockDevicesView')));
const ClockMonitorView = Loadable(lazy(() => import('../views/clock-monitor/ClockMonitorView')));
// Dashboard DT views
const HomeDtView = Loadable(lazy(() => import('@rflex-spa/react-core/dist/base/pages/work-address/Home')));
const InstanceOfView = Loadable(
  lazy(() => import('@rflex-spa/react-core/dist/base/pages/work-address/InstanceOfView'))
);
const SpreadSheetsView = Loadable(lazy(() => import('../views/spreadsheets/SpreadsheetsView')));
const SpreadsheetDetailView = Loadable(lazy(() => import('../views/spreadsheets/SpreadsheetDetailView')));

// Mantainers
const MaintainerView = Loadable(lazy(() => import('../views/mantainers')));
const ConfigShiftView = Loadable(lazy(() => import('../views/mantainers/configuration-shift')));
const AdministratorMaintainerView = Loadable(
  lazy(() => import('../views/mantainers/administrators/AdministratorMaintainerView'))
);
const RolesMaintainerView = Loadable(lazy(() => import('../views/mantainers/roles/RolesMaintainerView')));
const RolesHistoryView = Loadable(lazy(() => import('../views/mantainers/roles/RolesHistoryView')));

// Protocol improvement
const ProtocolImprovementView = Loadable(lazy(() => import('../views/protocol-improvement')));
const ProtocolImprovementByContract = Loadable(lazy(() => import('../views/protocol-improvement/contract')));
const ProtocolImprovementBySector = Loadable(lazy(() => import('../views/protocol-improvement/sector')));
const ProtocolImprovementByShiftTypeAndRotation = Loadable(
  lazy(() => import('../views/protocol-improvement/shift-type-and-rotations'))
);
const AbsenceShiftDetailView = Loadable(
  lazy(() => import('../views/protocol-improvement/absence-shift-detail/AbsenceShiftDetailView'))
);
// Attendance sheets
const AttendanceSheets = Loadable(lazy(() => import('../views/attendance-sheets')));

// Audit maintainer
const AuditMaintainerView = Loadable(lazy(() => import('../views/mantainers/audit')));

// Leave
const LeavesView = Loadable(lazy(() => import('../views/leave')));
const FoodLawView = Loadable(lazy(() => import('../views/leave/foodlaw')));
const MedicalRestLeave = Loadable(lazy(() => import('../views/leave/medical-rest-leave/MedicalRestLeaveView')));
const BulkLeaves = Loadable(lazy(() => import('../views/leave/bulk-leaves')));
// Extra hours
const ExtraHoursView = Loadable(lazy(() => import('../views/extra-hours')));

// Importers
const ImportersView = Loadable(lazy(() => import('../views/importers/ImporterView')));
const ShiftImporterView = Loadable(lazy(() => import('../views/importers/shift/ShiftImporterView')));
// Process validator
const DocumentManagementView = Loadable(lazy(() => import('../views/document-management')));
const ValidationCodeForm = Loadable(lazy(() => import('../views/document-management/validation-code-form')));
const DocumentValidator = Loadable(lazy(() => import('../views/document-management/document-validator')));

// Improvement hours
const GenericReportGDPView = Loadable(lazy(() => import('../views/reports/generic-report-gdp/GenericReportGDPView')));

